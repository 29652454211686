import '../sass/main.scss';
import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import 'tw-elements';


/*————————————————————————————————————————————————————*\
    ●❱ SLIDERS
\*————————————————————————————————————————————————————*/
// Slider noticias principales
const main_slider_id = document.querySelector('#main-carousel');
const main_slider_thumb = document.querySelector('#thumbnail-carousel');

if (main_slider_id && main_slider_thumb) {
    let main_news_slider = new Splide('#main-carousel', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false,
    });

    let thumbnails_news_slider = new Splide('#thumbnail-carousel', {
        fixedWidth: 100,
        fixedHeight: 60,
        gap: 10,
        rewind: true,
        pagination: false,
        isNavigation: true,
        arrows: false,
    });

    main_news_slider.sync(thumbnails_news_slider);

    main_news_slider.on('mounted', function () {
        let activeSlideIndex = main_news_slider.index;
        let thumbnails = document.querySelectorAll('#thumbnail-carousel li');
        let title_main_slider = document.querySelectorAll('#main-carousel li')[activeSlideIndex];
        let data_url = title_main_slider.querySelector('#title_main_slider').getAttribute('data-geturl')

        for (let i = 0; i < thumbnails.length; i++) {
            let thumbnail = thumbnails[i];

            if (i === activeSlideIndex) {
                thumbnail.querySelector('.link_thumnail').setAttribute('href', data_url);
            } else {
                thumbnail.querySelector('.link_thumnail').removeAttribute('href');
            }
        }
    });

    main_news_slider.on('move', function () {
        let activeSlideIndex = main_news_slider.index;
        let thumbnails = document.querySelectorAll('#thumbnail-carousel li');
        let title_main_slider = document.querySelectorAll('#main-carousel li')[activeSlideIndex];
        let data_url = title_main_slider.querySelector('#title_main_slider').getAttribute('data-geturl');
        let cont = 0;

        for (let i = 0; i < thumbnails.length; i++) {
            let thumbnail = thumbnails[i];

            if (i === activeSlideIndex) {
                thumbnail.querySelector('.link_thumnail').addEventListener('click', () => {
                    if (cont == 0) {
                        cont = cont + 1;
                        thumbnail.querySelector('.link_thumnail').href = data_url;
                    }
                });
            }
            thumbnail.querySelector('.link_thumnail').removeAttribute('href');
            cont = 0;
        }
    });

    main_news_slider.mount();
    thumbnails_news_slider.mount();


}

const morevideo_slider_id = document.querySelector('#more-videos');

if (morevideo_slider_id) {
    // Slider de mas videos bg azul
    let more_videos_slider = new Splide('#more-videos', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '9rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            768: {
                perPage: 1,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            640: {
                perPage: 1,
                perMove: 1,
                padding: '5rem',
                focus: 'center',
            },
            420: {
                perPage: 1,
                perMove: 1,
                padding: '3rem',
                focus: 'center',
            }
        },
    });

    more_videos_slider.mount();
}

const social_slider_id = document.querySelector('#social_slider');

if (social_slider_id) {
    //slider de redes sociales
    let social_slider = new Splide('#social_slider', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '18rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1280: {
                perPage: 2,
                padding: '3rem',
                focus: 'center',
            },
            1024: {
                perPage: 3,
            },
            420: {
                perPage: 1,
                padding: '1rem',
                width: 400,
            },
        },
    });

    social_slider.mount();
}

const more_news_id = document.querySelector('#more-news');

if (more_news_id) {
    //slider de mas noticias
    let more_news = new Splide('#more-news', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '9rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            768: {
                perPage: 1,
                perMove: 1,
                padding: '10rem',
                focus: 'center',
            },
            640: {
                perPage: 1,
                perMove: 1,
                padding: '5rem',
                focus: 'center',
            },
            420: {
                perPage: 1,
                perMove: 1,
                padding: '3rem',
                focus: 'center',
            },
        },
    });

    more_news.mount();
}

const related_notes_id = document.querySelector('#related-notes');

if (related_notes_id) {
    let related_notes = new Splide('#related-notes', {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        padding: '12rem',
        focus: 'center',
        pagination: false,
        mediaQuery: 'max',
        breakpoints: {
            1280: {
                perPage: 2,
                padding: '10rem',
            },
            1440: {
                perPage: 2,
                padding: '10rem',
            },
            1024: {
                perPage: 2,
                padding: '6rem',
            },
            768: {
                perPage: 1,
                padding: '1rem',
            },
            640: {
                perPage: 1,
                padding: '1rem',
            }
        },
    });

    related_notes.mount();
}

const gallery_images = document.querySelector('#gallery_images');
if (gallery_images) {
    const gallery = new Splide('#gallery_images', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({ Grid });
}

const gallery_audio = document.querySelector('#gallery_audios');
if (gallery_audio) {
    const gallery = new Splide('#gallery_audios', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({ Grid });
}

const gallery_videos = document.querySelector('#gallery_videos');
if (gallery_videos) {
    const gallery = new Splide('#gallery_videos', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({ Grid });
}

const gallery_maps = document.querySelector('#gallery_maps');
if (gallery_maps) {
    const gallery = new Splide('#gallery_maps', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        grid: {
            // You can define rows/cols instead of dimensions.
            dimensions: [[2, 3]],
            gap: {
                row: '6px',
                col: '6px',
            },
        },
        mediaQuery: 'max',
        breakpoints: {
            768: {
                grid: {
                    dimensions: [[2, 2]],
                }
            },
            420: {
                grid: {
                    dimensions: [[1, 1]],
                }
            }
        },
    })

    gallery.mount({ Grid });
}

const slide_detail_images = document.querySelector('#slide_detail_images');
const thumbnail_slide_detail_images = document.querySelector('#thumbnail_slide_detail_images');
const modalImageGallery = document.querySelector('#modal_image_gallery');


if (slide_detail_images && thumbnail_slide_detail_images) {
    // Slider de detalle de galeria de imagenes
    const slide_detail = new Splide('#slide_detail_images', {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false,
    });

    const thumbnail_slide_detail = new Splide('#thumbnail_slide_detail_images', {
        type: 'loop',
        gap: 8,
        rewind: true,
        pagination: false,
        isNavigation: true,
        autoWidth: true,
        focus: 'center',
        perPage: 3,
        perMove: 1,
        breakpoints: {
            600: {
                fixedWidth: 60,
                fixedHeight: 44,
            },
        },
    });

    slide_detail.sync(thumbnail_slide_detail);
    let modal;

    if (modalImageGallery) {
        const modal_gallery = new Splide('#modal_image_gallery', {
            type: 'fade',
            rewind: true,
            pagination: false,
        });
        thumbnail_slide_detail.sync(modal_gallery);

        modal_gallery.on('mounted', () => {
            modal_gallery.root.focus();
            modal_gallery.root.addEventListener('keydown', (event) => {
                if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                    modal_gallery.go('<');
                } else if (event.key === 'ArrowRight' || event.keyCode === 39) {
                    modal_gallery.go('>');
                }
            });
        });

        modal = modal_gallery;
    }

    thumbnail_slide_detail.on('mounted', () => {
        thumbnail_slide_detail.root.focus();
        thumbnail_slide_detail.root.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                thumbnail_slide_detail.go('<');
            } else if (event.key === 'ArrowRight' || event.keyCode === 39) {
                thumbnail_slide_detail.go('>');
            }
        });
    });


    slide_detail.mount();
    if (modalImageGallery) modal.mount();
    thumbnail_slide_detail.mount();



}

/*————————————————————————————————————————————————————*\
    ●❱ MODALS
\*————————————————————————————————————————————————————*/

// modal del galeria de imagenes
const expand = document.querySelector('.fullscreen-img');
if (expand) {
    let tag_img = document.querySelector('.imagepreview');
    expand.addEventListener('click', (e) => {
        e.preventDefault();
        let data_url = expand.getAttribute('data-orimage');
        tag_img.src = data_url;
    });
}

/*————————————————————————————————————————————————————*\
    ●❱ parallax for internal news (IMG)
\*————————————————————————————————————————————————————*/
const story_body = document.querySelector('.story__body.use_parallax');
let adsIsImg = false;
if (story_body) {
    const images = story_body.querySelectorAll('img');
    if (images.length > 0)
        adsIsImg = true;

    for (let i = 0; i < images.length; i++) {
        const img = images[i];
        const section = document.createElement('section');
        const divimg = document.createElement('div');
        const secondDiv = document.createElement('div');
        const imgUrl = img.getAttribute("src");

        section.classList.add('article_fullwidth_image');
        divimg.classList.add('simpleParallax');
        img.classList.add('parallax-image');
        secondDiv.style.backgroundImage = `url(${imgUrl})`;
        secondDiv.classList.add('divimage');

        story_body.insertBefore(section, img);
        section.appendChild(divimg);
        divimg.appendChild(img);
        divimg.insertAdjacentHTML('afterend', secondDiv.outerHTML);
    }

}

/*————————————————————————————————————————————————————*\
    ●❱ PROGRESS READING BAR
\*————————————————————————————————————————————————————*/

function updateProgressBar() {
    const article_detail = document.querySelector('.article_detail');
    if (article_detail) {
        const height_article = article_detail.offsetHeight;
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollPercent = `${(scrollTop / (height_article - windowHeight)) * 100}%`;

        document.querySelector('#progress-reading').style.setProperty('--progress', scrollPercent);
    }
}

document.addEventListener('scroll', updateProgressBar);
window.addEventListener('load', updateProgressBar);

/*————————————————————————————————————————————————————*\
    ●❱ parallax for internal news (code)
\*————————————————————————————————————————————————————*/
window.addEventListener('load', () => {
    if (adsIsImg) {
        const section = document.querySelector('.article_fullwidth_image');
        const secondDiv = document.querySelector('.divimage');
        if (section || secondDiv) {
            let leftOffset = section.offsetLeft;
            let withSection = section.offsetWidth;
            secondDiv.style.backgroundPositionX = `${leftOffset}px`;
            if (withSection > 0) {
                secondDiv.style.backgroundSize = `${withSection}px`;
            }
        }
    }
    else {
        let div_child = document.querySelector('.story__body.use_parallax div');
        if (div_child) {
            let frame = document.querySelector('.story__body div iframe');
            if (frame) {
                let frameDoc = frame.contentWindow.document;
                let linkImage = frameDoc.querySelector('#google_image_div a img');
                let link_ads = frameDoc.querySelector('#google_image_div a');

                if (linkImage) {
                    let img_a = link_ads.querySelector('img');
                    div_child.appendChild(link_ads);
                    link_ads.classList.add('article_fullwidth_image');
                    const secondDiv = document.createElement('div');
                    const imgUrl = link_ads.querySelector('img').getAttribute("src");

                    img_a.classList.add('parallax-image');
                    secondDiv.style.backgroundImage = `url(${imgUrl})`;
                    secondDiv.classList.add('divimage');

                    div_child.querySelector('a').appendChild(secondDiv);

                    frame.classList.add('hidden');
                }

            }

            let leftOffset = div_child.offsetLeft;
            let withSection = div_child.offsetWidth;
            document.querySelector('.divimage').style.backgroundPositionX = `${leftOffset}px`;
            if (withSection > 0) {
                document.querySelector('.divimage').style.backgroundSize = `${withSection}px`;
            }
        }
    }
});

window.addEventListener("resize", () => {
    const section = document.querySelector('.article_fullwidth_image');
    const secondDiv = document.querySelector('.divimage');
    const img_ad_code = document.querySelector('#google_image_div .img_ad');

    if (section) {
        let leftOffset = section.offsetLeft;
        let withSection = section.offsetWidth;
        secondDiv.style.backgroundPositionX = `${leftOffset}px`;
        if (withSection > 0) {
            secondDiv.style.backgroundSize = `${withSection}px`;

            if (img_ad_code) {
                img_ad_code.style.width = `${withSection}px`;
            }
        }

    }
});

/*————————————————————————————————————————————————————*\
    ●❱ Sent Contact Form
\*————————————————————————————————————————————————————*/
const form_contact = document.querySelector('#contact-form');
const icon_check = document.querySelector('#icon_check_form');
const icon_error = document.querySelector('#icon_error_form');
const msg_container = document.querySelector('.ct-form__message-text');

if (form_contact) {
    form_contact.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = e.target;
        if (form.classList.contains('ct-form')) {
            // Prevent the default form submit
            // e.preventDefault();

            const ele_message = form.querySelector('.ct-form__message')
            const formData = new FormData(form);
            const payload = {};
            formData.forEach(function (value, key) {
                payload[key] = value;
            });

            // Post data using the Fetch API
            fetch(form.action, {
                method: form.method,
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': e.target.csrfmiddlewaretoken.value,
                },
                body: formData
            }).then(response => {
                return response.json() //Convert response to JSON
            }).then(data => {
                if (data.sent) {
                    form.querySelector('.ct-form__form-wrapper').style.display = "none";
                    msg_container.innerHTML = data.msg;
                    ele_message.style.display = "flex";
                    icon_check.style.display = 'block';
                }
                else {
                    msg_container.innerHTML = data.msg;
                    ele_message.style.display = "flex";
                    icon_error.style.display = 'block';
                    form.querySelector('.ct-form__form-wrapper').style.display = "none";
                }
            }).then(
                setTimeout(() => {
                    icon_check.style.display = 'none';
                    icon_error.style.display = 'none';
                    ele_message.style.display = "none";
                    e.target.reset();
                    form.querySelector('.ct-form__form-wrapper').style.display = "grid";
                }, 5000)
            )
        }
    });
}

/*————————————————————————————————————————————————————*\
    ●❱ Mobile Social Network
\*————————————————————————————————————————————————————*/
const shareIcon = document.getElementById("share-icon");
const socialNetworks = document.querySelectorAll(".sticky-bar-mobile a");
if (shareIcon) {
  shareIcon.addEventListener("click", () => {
     shareIcon.classList.toggle("sticky-bar-open");
    socialNetworks.forEach(socialNetwork => {
      socialNetwork.classList.toggle("show-icons");
    });

  })
}
